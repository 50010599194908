// Generated by Framer (f501c86)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {V6TKUxn0F: {hover: true}};

const cycleOrder = ["V6TKUxn0F"];

const serializationHash = "framer-QMbch"

const variantClassNames = {V6TKUxn0F: "framer-v-6f8yna"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, BrcKtQgTy: title ?? props.BrcKtQgTy ?? "Accedi alla waitlist"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, BrcKtQgTy, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "V6TKUxn0F", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-QMbch", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-6f8yna", className)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"V6TKUxn0F"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 121, 34)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806)", ...style}} variants={{"V6TKUxn0F-hover": {backgroundColor: "rgb(252, 139, 64)"}}} {...addPropertyOverrides({"V6TKUxn0F-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0FsYXNrYSBSZWd1bGFy", "--framer-font-family": "\"Alaska Regular\", sans-serif", "--framer-font-size": "18px", "--framer-line-height": "140%", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Accedi alla waitlist</motion.p></React.Fragment>} className={"framer-1njgycy"} data-framer-name={"Text"} fonts={["CUSTOM;Alaska Regular"]} layoutDependency={layoutDependency} layoutId={"qKzoWyD0_"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "18px"}} text={BrcKtQgTy} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-QMbch [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QMbch .framer-8olcaz { display: block; }", ".framer-QMbch .framer-6f8yna { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 16px 22px 16px 22px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-QMbch .framer-1njgycy { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-QMbch .framer-v-6f8yna .framer-6f8yna { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-QMbch .framer-6f8yna { gap: 0px; } .framer-QMbch .framer-6f8yna > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-QMbch .framer-6f8yna > :first-child { margin-left: 0px; } .framer-QMbch .framer-6f8yna > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 57
 * @framerIntrinsicWidth 214
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"wQ_Mt_MdM":{"layout":["auto","auto"]}}}
 * @framerVariables {"BrcKtQgTy":"title"}
 * @framerImmutableVariables false
 */
const FramervcS6S272x: React.ComponentType<Props> = withCSS(Component, css, "framer-QMbch") as typeof Component;
export default FramervcS6S272x;

FramervcS6S272x.displayName = "Button";

FramervcS6S272x.defaultProps = {height: 57, width: 214};

addPropertyControls(FramervcS6S272x, {BrcKtQgTy: {defaultValue: "Accedi alla waitlist", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramervcS6S272x, [{family: "Alaska Regular", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/vcS6S272x:default", url: "https://framerusercontent.com/assets/ZeSoawZkbvQ0jJC3v0ATkvjmlcs.otf"}, url: "https://framerusercontent.com/assets/ZeSoawZkbvQ0jJC3v0ATkvjmlcs.otf"}])